.row {
  align-items: center;
}

.compassWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Adjust as needed */
  min-height: 340px;
}

.angle {
  color: #fff;
  /* fontSize: height / 25; Cannot be converted directly to CSS */
  margin-bottom: 30px;
}

.arrow {
  position: absolute;
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.compass_bg {
  /* height: width - 80; Cannot be converted directly to CSS */
  /* width: width; Cannot be converted directly to CSS */
  object-fit: contain;
}

.arrow, .compassBg {
  position: absolute;
  max-width: 340px; /* Adjust as needed */
  max-height: 340px; /* Adjust as needed */
}
  